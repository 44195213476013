<template>
  <navigation index="reportMaintain" ref="navigation">
     <div class="topnavigation" style="height: 60px; width: 100%; display: flex; align-items: center">
      <!-- <el-button style="height: 40px;">单据报表</el-button> -->
      <el-button style="height: 40px;" @click="skip('ReportMaintainPay')">营业额报表</el-button>
      <!-- <el-button style="height: 40px;">图形化报表</el-button> -->
    </div>
    <div
      :style="{ height: clientHeight - 80 + 'px' }"
      style="overflow: auto; width: calc(100%-30px)"
    >
    <br />
      <el-card
        class="box-card"
        style="
          margin-top: 100px;
          width: calc(100% - 10px);
          margin: 0 auto;
          border: 1px solid var(--el-menu-border-color);
        "
      >
        <template #header>
          <span> {{ tools.GetLanguageValue("web.今日订单") }} </span>
        </template>

        <el-row style="padding: 20px; text-align: center">
          <el-col :span="6">
            <!-- <el-countdown title="当前时间" format="HH:mm:ss" :value="Time" /> -->
            <el-statistic
              :title="tools.GetLanguageValue('web.总订单量')"
              :value="RepairsToday.CreateCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.维修数量')"
              :value="RepairsToday.CreateMaintainCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.完成维修的数量')"
              :value="RepairsToday.OverMaintainCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.日期')"
              :value="RepairsToday.DateFormat"
            >
            </el-statistic>
          </el-col>
        </el-row>

        <el-row style="padding: 20px; text-align: center">
          <el-col :span="6">
            <!-- <el-countdown title="当前时间" format="HH:mm:ss" :value="Time" /> -->
            <el-statistic
              :title="tools.GetLanguageValue('web.总订单量')"
              :value="RepairsYesterday.CreateCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.维修数量')"
              :value="RepairsYesterday.CreateMaintainCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.完成维修的数量')"
              :value="RepairsYesterday.OverMaintainCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.日期')"
              :value="RepairsYesterday.DateFormat"
            >
            </el-statistic>
          </el-col>
        </el-row>
      </el-card>

      <br />
    
      <el-card
        class="box-card"
        style="
          margin-top: 100px;
          width: calc(100% - 10px);
          margin: 0 auto;
          border: 1px solid var(--el-menu-border-color);
        "
      >
        <template #header>
          <span> {{ tools.GetLanguageValue("web.本月信息") }}</span>
        </template>

        <el-row style="padding: 20px; text-align: center">
          <el-col :span="6">
            <!-- <el-countdown title="当前时间" format="HH:mm:ss" :value="Time" /> -->
            <el-statistic
              :title="tools.GetLanguageValue('web.总订单量')"
              :value="RepairsThisMonth.CreateCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.维修数量')"
              :value="RepairsThisMonth.CreateMaintainCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.完成维修的数量')"
              :value="RepairsThisMonth.OverMaintainCount"
            />
          </el-col>
          <el-col :span="6">
            <el-statistic
              :title="tools.GetLanguageValue('web.本月返修')"
              :value="RepairsThisMonth.ReworkingCount"
            >
            </el-statistic>
          </el-col>
        </el-row>
      </el-card>
      <br />
      <el-card
        class="box-card"
        style="
          width: calc(100% - 10px);
          margin: 0 auto;
          border: 1px solid var(--el-menu-border-color);
        "
      >
        <template #header>
          <span> {{ tools.GetLanguageValue("web.30天走势") }} </span>
        </template>

        <div id="main" style="width: 100%; height: 200px"></div>
      </el-card>
    </div>
  </navigation>
</template>
  <script>
import axios from "../../../commons/AxiosMethod.js";
import { ElMessageBox, ElMessage } from "element-plus";
import * as echarts from "echarts";
import navigation from "../../../components/Modules/Navigation.vue";
import tools from "../../../commons/tools.js";

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      BusinessVolumeWhere: {},
      OrderCount: {},
      TrendBusinessVolumeWhere: {},
      ReportDateByTime: {},

      RepairsToday: {}, //今日
      RepairsYesterday: {}, //昨日
      RepairsThisMonth: {}, //本月
      tools: tools,
    };
  },
  name: "App",
  mounted() {
    this.GetRepairsToday();
    this.GetRepairsThisMonth();
    this.GetRepairs30DayTrendChart();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        this.MakertItemHeight = document.documentElement.clientHeight - 370;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 120;
    this.MakertItemHeight = this.clientHeight - 370;
  },

  components: { navigation, tools },
  methods: {
    //获取30天的折线图
    GetRepairs30DayTrendChart() {
      axios.apiMethod(
        "/report/StoreReport/GetRepairs30TrendChart",
        "get",
        {},
        (result) => {
          var chartDom = document.getElementById("main");
          var myChart = echarts.init(chartDom);

          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: "category",
              data: result.Data.data,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: result.Data.SeriesDataItems,
                type: "bar",
              },
            ],
          };

          myChart.setOption(option);
        },
        (d) => {}
      );
    },
    //本月
    GetRepairsThisMonth() {
      axios.apiMethod(
        "/report/StoreReport/GetRepairsThisMonth",
        "get",
        {},
        (result) => {
          this.RepairsThisMonth = result.Data;
        },
        (d) => {}
      );
    },
    //今日维修信息
    GetRepairsToday() {
      axios.apiMethod(
        "/report/StoreReport/GetcombineRepairsTodayAndYesterday",
        "get",
        {},
        (result) => {
          this.RepairsToday = result.Data.RepairsToday;
          this.RepairsYesterday = result.Data.RepairsYesterday;
        },
        (d) => {}
      );
    },
    skip(url) {
 
      this.$router.push({
        path: url,
        query: {
          s: Date.now(),
        },
      });
    },
  },
};
</script>
    
  <style scoped>
.flex-grow {
  flex-grow: 1;
}
</style>
   